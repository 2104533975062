* {
  font-family: 'Inconsolata', monospace;
}
.home {
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.intro {
  color: white;
  width: 50%;
  margin: auto;
  padding: 2%;
  background: rgba(50, 50, 50, .9);
  text-align: center;
  border-radius: 30px;
  p {
    font-size: 17px;
  }
  span, a {
    color: #B2560D;
  }
  a {
    font-weight: bolder;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
    color: #172808;
  }
}

