.background { 
  background-image: url('./img/fog.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 100vh;
  width: 100%;
  padding: 0;
  overflow: auto;
}
